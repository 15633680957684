import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import T from 'prop-types'
import { useRef, useState } from 'react'

import Button from '@/components/Button'

import getTopic from '@/lib/topic'

import BEMHelper from '@/helpers/bem'
import styles from './Styles.module.scss'
const bem = BEMHelper(styles)

export function Entry(props) {
  const router = useRouter()
  const { slug, title, className, topic, titleNode, topicTheme, square, file, typeTag, image } =
    props

  const type = props.type || props._type
  const Title = titleNode || 'h2'

  const topicProps = topic?.slug ? getTopic(topicTheme) : null

  const style = topicProps && {
    '--color-background': topicProps.background,
    '--color-promoted': topicProps.promoted,
  }

  const typePaths = {
    talkingType: ['samtalekort', 'Samtalekort'],
    quiz: ['quiz', 'Quiz'],
    resource: ['hjelpemidler', 'Hjelpemidler'],
    presentation: ['presentasjon', 'Presentasjon'],
    subTopic: ['samling', 'Samlinger'],
  }

  const hasLongWord = (title ?? '').split(' ').some((str) => str.length > 16)
  const resourceType = file && getFileType(file)
  const imgSrc = image?.asset ? getImageUrl(image).width(1200).height(600).url() : image
  // const hasImage = !!(type === 'resource' && imgSrc)

  const query = type === 'talkingType' ? { referer: router.asPath } : {}

  return (
    <li {...bem('item', '', className)} style={style}>
      <Link
        href={{ pathname: `/${typePaths[type][0]}/${slug}`, query }}
        {...bem('link', { square })}
      >
        <div {...bem('content')}>
          {type === 'talkingType' && <span {...bem('topic')}>Samtalekort</span>}
          {type === 'quiz' && <span {...bem('topic')}>Quiz</span>}
          {type === 'resource' && <span {...bem('topic')}>{typeTag ?? resourceType}</span>}
          {type === 'presentation' && <span {...bem('topic')}>Presentasjon</span>}
          {type === 'subTopic' && <span {...bem('topic')}>Samling</span>}

          <div {...bem('illustration')}>
            {type === 'talkingType' && (
              <Image src={`/icons/samtalekort.png`} alt="" width={600} height={600} />
            )}
            {type === 'quiz' && <Image src={`/icons/quiz.png`} alt="" width={600} height={600} />}
            {type === 'resource' && (
              <Image src={`/icons/ressurser.png`} alt="" width={600} height={600} />
            )}
            {type === 'presentation' && (
              <Image src={`/icons/presentasjon.png`} alt="" width={600} height={600} />
            )}
            {type === 'subTopic' && imgSrc && (
              <Image src={imgSrc} alt="" width={600} height={600} />
            )}
          </div>
          {/* {hasImage && (
            <div {...bem('poster')}>
              <Image src={imgSrc} alt="" width={800} height={400} {...bem('poster-img')} />
            </div>
          )} */}
          <Title {...bem('title', { 'long-word': hasLongWord })}>{title}</Title>
        </div>
      </Link>
    </li>
  )
}

const FILE_TYPES = {
  pdf: 'Arbeidsark',
  doc: 'Dokument',
  docx: 'Dokument',
  ppt: 'Presentasjon',
  pptx: 'Presentasjon',
  key: 'Presentasjon',
  xls: 'Dokument',
  xlsx: 'Dokument',
  mp3: 'Lyd',
}

function getFileType(file) {
  if (!file) {
    return null
  }

  const fileType = file.split('.').pop()

  return FILE_TYPES[fileType] || 'Ressurs'
}

export default function LearningAidsEntry({ items = [], title, id }) {
  const [showMore, setShowMore] = useState(false)
  const entriesRef = useRef(null)

  if (!items?.length) {
    return null
  }

  const displayedItems = showMore ? items : items.slice(0, 3)
  const remainingItems = items.length - displayedItems.length

  return (
    <nav {...bem('')} id={id} ref={entriesRef}>
      {title && <h2 {...bem('main-title')}>{title}</h2>}

      <ul {...bem('list')}>
        {displayedItems.map((item) => (
          <Entry key={item._id || item.objectID || item.slug} {...item} />
        ))}
      </ul>

      {items.length > 3 && !showMore && (
        <Button onClick={() => setShowMore(true)} more>
          {remainingItems} flere
        </Button>
      )}
    </nav>
  )
}

const entryPropTypes = {
  _id: T.string,
  slug: T.string.isRequired,
  title: T.string.isRequired,
  types: T.object,
  entryType: T.string,
  objectID: T.string,
  purpose: T.string,
  preamble: T.string,
  count: T.number,
  className: T.string,
  topic: T.object,
  titleNode: T.string,
  topicTheme: T.object,
  square: T.bool,
}

LearningAidsEntry.propTypes = {
  id: T.string,
  title: T.string,
  items: T.arrayOf(T.shape(entryPropTypes)),
}

Entry.propTypes = entryPropTypes
